* {
  scroll-behavior: smooth;
}
.app {
  min-height: 100vh;
  background-color: darkslategray;
  color: whitesmoke;
  padding-top: 130px;
  padding-bottom: 70px;
}

@media (max-width: 700px) {
  .app {
    padding-top: 70px;
  }
}

.pageTitle {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  font-family: 'Quicksand', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 5vw;
  margin-top: 15px;
  padding: 4px;
  border-radius: 50px;
  color: whitesmoke;
}

@media (max-width: 1000px) {
  .pageTitle {
    font-size: 5.4vw;
    margin-top: 5px;
  }
}
