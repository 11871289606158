.header {
  width: 100%;
  cursor: pointer;
  position: fixed;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  background-color: darkslategray;
  font-family: 'Quicksand', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 5vw;
  padding-bottom: 15px;
  box-shadow: 0px 1px 5px black;
  color: whitesmoke;
  z-index: 100;
}

@media (max-width: 1000px) {
  .header {
    padding-top: 15px;
    font-size: 6.4vw;
  }
}