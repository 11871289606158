@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  scroll-behavior: smooth;
}
.app {
  min-height: 100vh;
  background-color: darkslategray;
  color: whitesmoke;
  padding-top: 130px;
  padding-bottom: 70px;
}

@media (max-width: 700px) {
  .app {
    padding-top: 70px;
  }
}

.pageTitle {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  font-family: 'Quicksand', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 5vw;
  margin-top: 15px;
  padding: 4px;
  border-radius: 50px;
  color: whitesmoke;
}

@media (max-width: 1000px) {
  .pageTitle {
    font-size: 5.4vw;
    margin-top: 5px;
  }
}

.header {
  width: 100%;
  cursor: pointer;
  position: fixed;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  background-color: darkslategray;
  font-family: 'Quicksand', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 5vw;
  padding-bottom: 15px;
  box-shadow: 0px 1px 5px black;
  color: whitesmoke;
  z-index: 100;
}

@media (max-width: 1000px) {
  .header {
    padding-top: 15px;
    font-size: 6.4vw;
  }
}
.carouselItem {
  display: flex;
  flex-direction: column;
  object-fit: contain;
  padding: 10px;
}
.carouselItem__img {
  border-radius: 10px;
  margin-bottom: 5px;
  box-shadow: 0px 0px 5px black;
}
.ContentModal__landscape {
  object-fit: contain;
  border-radius: 10px;
}

.ContentModal__portrait {
  display: none;
  object-fit: contain;
  border-radius: 10px;
}

.tagline {
  padding-bottom: 10px;
  align-self: center;
}

.ContentModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.ContentModal::-webkit-scrollbar {
  display: none;
}

.ContentModal__about {
  padding: 10px;
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  justify-content: space-evenly;
  font-weight: 300;
}

.ContentModal__title {
  height: 12%;
  font-size: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ContentModal__description {
  display: flex;
  height: 40%;
  overflow-y: scroll;
  padding: 15px;
  border-radius: 20px;
  scrollbar-width: thin; /* Firefox */
  box-shadow: inset 0 0 5px #000000;
  text-align: justify;
}

.ContentModal__description::-webkit-scrollbar {
  display: none;
}

@media (min-width: 835px) {
  .ContentModal__landscape {
    display: none;
  }
  .ContentModal__portrait {
    display: flex;
    width: 38%;
  }
  .ContentModal {
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 0;
  }
  .ContentModal__about {
    width: 58%;
    padding: 0;
    height: 100%;
  }
  .ContentModal__title {
    font-size: 3.5vw;
  }
  .ContentModal__description {
    font-size: 22px;
  }
}
.media {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 5px;
  margin: 5px 0;
  background-color: darkslategrey;
  border-radius: 16px;
  position: relative;
  font-family: 'Quicksand', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.media:hover {
  background-color: crimson;
  color: whitesmoke;
}

@media (max-width: 550px) {
  .media {
    width: 46%;
  }
}

.poster {
  border-radius: 10px;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 17px;
  padding: 8px 0;
}

.subTitle {
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
  padding-bottom: 3px;
}

.search {
  display: flex;
  margin: 15px 0;
}

.angesagt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
